/**
 * ruleTypes: 规则类型
 * genRules： rule 生成函数
 * ctx： Vue实例
 */
import { genModels } from '@/plugins/widget/c-form/rules';
import PlanSelector from '@/components/PlanSelector/PlanSelector.vue';
export var models = genModels(function (genRules, ctx) {return [
  {
    span: 12,
    kname: 'goodsName',
    itemProps: {
      label: '货品名称：',
      rules: [
      genRules('require')] } },



  {
    span: 12,
    kname: 'deptId',
    itemProps: {
      label: '归属部门：',
      rules: [
      genRules('require')] },


    component: {
      name: 'selector',
      props: {
        type: 'depts' } } },



  {
    span: 12,
    kname: 'typeIdArr',
    itemProps: {
      label: '货品分类：',
      rules: [
      genRules('require', undefined, undefined, 'blur')] },


    component: {
      name: 'selector',
      props: {
        type: 'goods',
        clearable: true,
        cascader: true } } },



  {
    span: 12,
    kname: 'model',
    itemProps: {
      label: '规格型号：',
      rules: [
      genRules('require')] } },



  {
    span: 12,
    kname: 'goodsNum',
    itemProps: {
      label: '数量：',
      rules: [
      genRules('require'),
      genRules('int')] } },



  {
    span: 12,
    kname: 'unit',
    itemProps: {
      label: '计量单位：',
      rules: [
      genRules('require')] } },



  {
    span: 12,
    kname: 'prestore',
    itemProps: {
      label: '预存天数：',
      rules: [
      genRules('require'),
      genRules('int')] } },



  {
    span: 12,
    kname: 'goodsLocationNo',
    itemProps: {
      label: '位置：',
      rules: [
      genRules('require')] },


    component: {
      name: PlanSelector } },


  {
    span: 12,
    kname: 'goodsSource',
    itemProps: {
      label: '货品来源：',
      rules: [
      genRules('require')] } },



  {
    span: 12,
    kname: 'containerId',
    itemProps: {
      label: '货箱归属：' },

    component: {
      name: 'selector',
      props: {
        type: 'syscontainers',
        clearable: true,
        fliterable: true } } }];});






// form 默认值
export var formData = function formData() {return {};};